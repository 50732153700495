import {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  lazy,
  Suspense,
  memo,
  useContext
} from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import {
  SORT_OPTIONS,
  DEFAULT_PROPERTY_SORT,
  darkBlue,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  white,
  coolGray50,
  SAVED_SEARCHES_URL,
  HAPI_SIGN_UP_SAVE_SEARCH_URL
} from '../../constants'
import { IPropertiesContext, PropertiesContext } from '../../contexts/PropertiesContext'
import { UserContext, IUserContext } from '../../contexts/UserContext'
import { SavedHomesProvider } from '../../contexts/SavedHomesContext'
import {
  ACTION_STATE,
  ACTION_TAP,
  ACTION_VIEW,
  trackEvent,
  sendToGtmDataLayer
} from '../../utils/tracking'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { useHideOnClickElsewhere } from '../../hooks/useHideOnClickElsewhere'
import { Flex } from '../common/Flex'
import { FallbackLoadingSpinner } from '../common/FallbackLoadingSpinner'
import { StyledBody, Title, BodyLeft } from '../common/PageBody'
import { StyledModalBackground, StyledModalContainer } from '../common/Modal'
import { PaginationSummary } from '../common/Pagination/PaginationSummary'
import { LoadingSpinner } from '../common/LoadingSpinner'
import { ShowMapButton } from '../ShowMapButton'
import { SearchBar } from '../SearchBar'
import { Filters } from '../Filters'
import { ISort } from '../Sort'
import { ListView } from '../ListView'
import { BreadCrumbs } from '../BreadCrumbs'
import { PropertySearchToast } from '../PropertySearchToast'
import { buildSearchUrl } from '../../utils/buildSearchUrl'
import { buildRequestFilters } from '../../utils/buildRequestFilters'
import { requestData } from '../../utils/requestData'
import { getUtmParamsFromUrl } from '../../utils/getUtmParamsFromUrl'
import { getParamObjects } from '../../utils/parseParams'
import {
  SaveSearchConfirm,
  PostFavoriteSearchModal,
  SavedSearchPromptModal,
  ISavedSearchBodyRequest
} from '../SavedPropertySearch'
import { Faq } from '../Faq'
import { IPropertyListing } from '../../contexts/PropertyContext'
import { IPropertySearchBodyProps } from './types'
import flagsmith from 'flagsmith'
import { CallMeMaybeModal } from '../CallMeMaybeModal'
import { NewBuyerLeadModal } from '../NewBuyerLeadModal'
import { useLazyCheckoutSession } from '../../hooks/useCheckoutSession'
import { useSearchParams } from 'react-router-dom'
import { UltraRateModal } from '../UltraRateModal'
import { AgentConfirmation } from '../AgentConfirmation'
import UltraRateToast from '../UltraRateToast'
import { TopAgentsInState } from '../TopAgentsInState'
import { ILocationContext, LocationContext } from '../../contexts/LocationContext'

const Map = lazy(
  () => import('../PropertySearchMap/PropertySearchMapContainer/PropertySearchMapContainer')
)
const SignInSignUp = lazy(() => import('../SignInSignUp/SignInSignUp'))
const LeadFormModal = lazy(() => import('../LeadForm/LeadForm'))

const SearchPageTitle = styled(Title)`
  padding: 0;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding-top: 16px;
  }
`

const MapLoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media (min-width: ${BREAKPOINT_M}px) {
    width: auto;
    flex: 3 3;
  }
`

const StyledFilterList = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 18px;
  position: ${props => (props.showMap ? 'absolute' : 'sticky')};
  top: ${props => (props.showMap ? 114 : 58)}px;
  z-index: ${props => (props.mobileFilterPopoverOpen || props.isModalVisible ? '6' : '3')};
  background: ${white};

  ${props => {
    if (props.showMap) {
      return `
        left: 0;
        right:0;
        padding: 16px 16px 0 16px;
      `
    }
  }}

  &[data-hide-search-bar=true] {
    top: ${props => (props.showMap ? 56 : -16)}px;
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    top: ${props => (props.showMap ? 124 : 66)}px;

    &[data-hide-search-bar='true'] {
      top: ${props => (props.showMap ? 50 : -8)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    margin-top: inherit;
    padding-bottom: 24px;
    top: ${props => (props.showMap ? 144 : 72)}px;
    ${props => {
      if (props.showMap) {
        return `
            padding-left: 32px;
            padding-right: 32px;
        `
      }
    }}
    &[data-hide-search-bar=true] {
      top: ${props => (props.showMap ? 60 : -14)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    top: ${props => (props.showMap ? 146 : 72)}px;

    &[data-hide-search-bar='true'] {
      top: ${props => (props.showMap ? 72 : -2)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    position: sticky;
    padding-left: 0;
    padding-right: 0;
    top: 74px;

    &[data-hide-search-bar='true'] {
      top: 0px;
    }
  }
`

const ModalBackground = styled(StyledModalBackground)`
  @media (max-width: ${BREAKPOINT_S}px) {
    justify-content: flex-end;

    & > div {
      flex-basis: calc(100% - 32px);
      display: flex;
      flex-direction: column;
    }
  }
`

const MortgagePromptBackground = styled(ModalBackground)`
  width: 100%;
  z-index: 5;

  @media (min-width: ${BREAKPOINT_XL}px) {
    left: unset;
    position: absolute;
  }
  @media (max-width: ${BREAKPOINT_S}px) {
    justify-content: center;
    flex-direction: row;
  }
`

const MortgagePromptContainer = styled(StyledModalContainer)`
  background-color: unset;
  position: absolute;
  width: 100%;
  height: calc(100% + 72px);
  top: -72px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLeadFormContainer = styled(StyledModalContainer)`
  margin-top: 45px;
  overflow-y: auto;
`

const SortFlex = styled(Flex)`
  position: ${props => (props.showMap ? 'absolute' : 'sticky')};
  top: ${props => (props.showMap ? 168 : 132)}px;
  z-index: 2;
  background: ${white};
  padding-bottom: 18px;
  border-bottom: 1px solid ${coolGray50};

  ${props => {
    if (props.showMap) {
      return `
        left: 0;
        right:0;
        padding: 18px 16px 18px 16px;
      `
    }
  }}

  &[data-hide-search-bar=true] {
    top: ${props => (props.showMap ? 112 : 58)}px;
  }

  @media (min-width: ${BREAKPOINT_S}px) {
    top: ${props => (props.showMap ? 180 : 150)}px;

    &[data-hide-search-bar='true'] {
      top: ${props => (props.showMap ? 112 : 78)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    top: ${props => (props.showMap ? 200 : 146)}px;
    ${props => {
      if (props.showMap) {
        return `
            padding-left: 32px;
            padding-right: 32px;
        `
      }
    }}

    &[data-hide-search-bar=true] {
      top: ${props => (props.showMap ? 115 : 60)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    top: ${props => (props.showMap ? 200 : 146)}px;

    &[data-hide-search-bar='true'] {
      top: ${props => (props.showMap ? 128 : 70)}px;
    }
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    position: sticky;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    top: 150px;

    &[data-hide-search-bar='true'] {
      top: 76px;
    }
  }
`

export const PropertySearchBodyComponent: React.FC<IPropertySearchBodyProps> = ({
  searchParams,
  currentUser,
  currentUserLoading,
  visitorId,
  addSavedHome,
  removeSavedHome,
  firstFavoriteInSession,
  setMostRecentFavoritedAt,
  appliedFilters,
  filters,
  sorts,
  setSorts,
  propertiesData,
  currentPage,
  resetCurrentPage,
  resultsTitle,
  locationTitle,
  currentSlug,
  showMoreSearches,
  setShowMoreSearches,
  savedSearches,
  pageType,
  bedroomCount,
  areaType,
  savedSearchMatchesFilters,
  pageAreaType,
  totalPropertiesCount,
  currentShape,
  locationDescription,
  mobileFilterPopoverOpen,
  setMobileFilterPopoverOpen,
  isModalVisible,
  setIsModalVisible,
  getFaqData,
  faqData,
  city,
  state,
  propertiesLoading,
  totalFeaturedCount,
  confirmAgent,
  setConfirmAgent
}) => {
  const { utmCampaign } = getUtmParamsFromUrl()
  const activeTest = utmCampaign === '15792145582' && process.env.REACT_APP_ACTIVE_SEM_TEST
  const [routerSearchParams, setSearchParams] = useSearchParams()

  const {
    ref: signUpModalRef,
    isVisible: signInSignUpIsVisible,
    setIsVisible: setSignInSignUpIsVisible
  } = useHideOnClickElsewhere(false)

  const {
    ref: saveSearchConfirmRef,
    isVisible: saveSearchConfirmIsVisible,
    setIsVisible: setSaveSearchConfirmIsVisible
  } = useHideOnClickElsewhere(false)

  const {
    ref: postFavoriteSearchRef,
    isVisible: postFavoriteSearchIsVisible,
    setIsVisible: setPostFavoriteSearchIsVisible
  } = useHideOnClickElsewhere(false)

  const {
    ref: savedSearchPromptRef,
    isVisible: savedSearchPromptIsVisible,
    setIsVisible: setSavedSearchPromptIsVisible
  } = useHideOnClickElsewhere(false)

  const {
    ref: leadFormModalRef,
    isVisible: leadFormModalIsVisible,
    setIsVisible: setLeadFormModalIsVisible
  } = useHideOnClickElsewhere(false)

  const { desktop } = useBreakpoint()
  const bodyRef = useRef<HTMLDivElement>(null)

  const [showMap, setShowMap] = useState(desktop)
  const [initialShowMap, setInitialShowMap] = useState(desktop)
  const [signInSignUpVariation, setSignInSignUpVariation] = useState<
    'signIn' | 'signUp' | 'confirm'
  >('signIn')
  const [signInEmail, setSignInEmail] = useState('')
  const [signInAddress, setSignInAddress] = useState<string>('')
  const [signInSource, setSignInSource] = useState<string | null>(null)
  const [hasSubmittedEmail, setHasSubmittedEmail] = useState<boolean>(false)
  const [savedSearchSignIn, setSavedSearchSignIn] = useState<boolean>(false)
  const [savedSearchCreated, setSavedSearchCreated] = useState<boolean>(false)
  const [requestError, setRequestError] = useState<boolean>(false)
  const [resentLoginLink, setResentLoginLink] = useState<boolean>(false)
  const [sentLoginLinkTimes, setSentLoginLinkTimes] = useState<number>(0)
  const [showToast, setShowToast] = useState<boolean>(false)
  const [toastVariation, setToastVariation] = useState<
    | 'requestError'
    | 'savedSearchCreated'
    | 'savedSearchLimit'
    | 'resentLoginLink'
    | 'homeFavorited'
    | 'leadSubmitted'
  >('savedSearchCreated')
  const [disableSavedSearch, setDisableSavedSearch] = useState<boolean>(false)
  const [lastFavoritedProperty, setLastFavoritedProperty] = useState<IPropertyListing>(null)
  const [pendingRequestUuid, setPendingRequestUuid] = useState(null)

  const pageTypeTitleMapping = {
    condo: 'Condos for Sale',
    sfh: 'Single Family Homes for Sale',
    townhome: 'Townhomes for Sale',
    mobile: 'Mobile Homes for Sale',
    bedroom: `${bedroomCount} Bedroom Homes for Sale`,
    newest: 'Newest Listings',
    sold: 'Recently Sold Homes'
  }

  const [leadFormVariation, setLeadFormVariation] = useState<'firstView' | 'firstViewConfirmation'>(
    'firstView'
  )
  const titleText = pageTypeTitleMapping[pageType] || 'Homes for Sale'
  const propertiesContext = useContext(PropertiesContext) as IPropertiesContext
  const {
    user: { fetchUserData, sessionId }
  } = useContext(UserContext) as IUserContext

  const { searchAreaType } = useContext(LocationContext) as ILocationContext

  const searchString = window.location.search
  const { gold_rush: goldRush } = getParamObjects(searchString)
  const { session_id: checkoutSessionId } = getParamObjects(searchString)

  const { isSubscriptionPaid, trigger: checkoutSessionRequest } = useLazyCheckoutSession({
    sessionId: checkoutSessionId
  })

  const searchCreatedParamPresent = !!routerSearchParams.get('save_search')
  const cmmLeadId = routerSearchParams.get('cmm')
  const newBuyer = routerSearchParams.get('new_buyer_client')
  const [callMeMaybeModal, setCallMeMaybeModal] = useState<boolean>(
    Boolean(cmmLeadId && currentUser?.showCallMeMaybe)
  )

  const [newBuyerLead, setNewBuyerLead] = useState<boolean>(Boolean(newBuyer))
  const [ultraRateModal, setUltraRateModal] = useState<boolean>(false)
  const [callMeMaybeState, setCallMeMaybeState] = useState('initial')
  const showSearchBar = false

  const goldRushFeatureFlag = flagsmith.hasFeature('gold-rush')
  const showGoldRush = goldRushFeatureFlag || goldRush
  const isGoldRushSubscribed =
    currentUser?.goldRushSubscribed || isSubscriptionPaid || !!routerSearchParams.get('session_id')
  const hasFeatured = useMemo(
    () => propertiesData.some(propertyData => propertyData.featured),
    [propertiesData]
  )

  const [unlockMortgagePromptIsVisible, setUnlockMortgagePromptIsVisible] = useState(false)
  const [paywallPropertyData, setPaywallPropertyData] = useState({})
  const [showOnboarding, setShowOnboarding] = useState(unlockMortgagePromptIsVisible)

  useEffect(() => {
    setCallMeMaybeModal(Boolean(cmmLeadId && currentUser?.showCallMeMaybe))
  }, [currentUser?.showCallMeMaybe])

  useEffect(() => {
    if (activeTest === 'prompt_saved_search' && !searchCreatedParamPresent) {
      setTimeout(() => {
        setSavedSearchPromptIsVisible(true)
        trackEvent(ACTION_VIEW, 'view_SEM_test_saved_search_modal')
      }, 30000)
    }

    if (activeTest === 'search_page_lead_form_timer') {
      setTimeout(() => {
        setLeadFormModalIsVisible(true)
        trackEvent(ACTION_VIEW, 'view_SEM_test_lead_modal_on_search')
      }, 30000)
    }
  }, [
    activeTest,
    setSavedSearchPromptIsVisible,
    setLeadFormModalIsVisible,
    searchCreatedParamPresent
  ])

  useEffect(() => {
    if (currentUser && !currentUser?.goldRushSubscribed && showGoldRush) {
      checkoutSessionRequest()
    }

    if (!currentUserLoading && showGoldRush && hasFeatured) {
      setUnlockMortgagePromptIsVisible(
        !isGoldRushSubscribed &&
          routerSearchParams.get('trial-modal') !== 'false' &&
          !routerSearchParams.get('session_id')
      )
    }
  }, [currentUser, currentUserLoading, showGoldRush, isGoldRushSubscribed, hasFeatured])

  useEffect(() => {
    setShowOnboarding(unlockMortgagePromptIsVisible)
  }, [unlockMortgagePromptIsVisible])

  const listScrollListener = useCallback(listNode => {
    const handleScroll = () => {
      if (!showMoreSearches) {
        setShowMoreSearches(true)
        listNode.removeEventListener('wheel', handleScroll)
      }
    }

    listNode?.addEventListener('wheel', handleScroll)

    return () => listNode?.removeEventListener('wheel', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetShowMap = (shouldShowMap: boolean): void => {
    if (shouldShowMap) {
      setInitialShowMap(true)
      setShowMap(true)
    } else {
      setShowMap(false)
    }
  }

  const handleModalBlur = (e: any) => {
    if (!e.currentTarget.contains(e.target)) {
      setSignInSignUpIsVisible(false)
      setSaveSearchConfirmIsVisible(false)
      setPostFavoriteSearchIsVisible(false)
      setSavedSearchPromptIsVisible(false)
      setLeadFormModalIsVisible(false)
      setConfirmAgent(false)
    }
  }

  const maybeShowPostFavoriteSearchModal = (property: IPropertyListing) => {
    if (!currentUser || !firstFavoriteInSession()) {
      return
    }

    setToastVariation('homeFavorited')
    setShowToast(true)
    setLastFavoritedProperty(property)
    setPostFavoriteSearchIsVisible(true)
    setMostRecentFavoritedAt(new Date().getTime())
  }

  const closeParamModal = (param: string) => {
    setNewBuyerLead(false)
    setConfirmAgent(false)
    routerSearchParams.delete(param)
    setSearchParams(routerSearchParams)
  }

  const triggerAgentConfirmation = (uuid: string) => {
    setPendingRequestUuid(uuid)
    setConfirmAgent(true)
  }

  const favoriteProperty = (addingFavorite: boolean, property: IPropertyListing) => {
    if (!currentUser?.id && !hasSubmittedEmail) {
      setSignInSource('Favorite')
      setSignInSignUpVariation('signIn')
      setSignInAddress(property.hlFullAddress)
      setSignInSignUpIsVisible(true)
    }

    const updateSavedProperty = addingFavorite ? addSavedHome : removeSavedHome
    const userParam = currentUser ? `user_id=${currentUser.id}` : `visitor_id=${visitorId}`
    const requestParams = `?${userParam}&property_uuid=${property.propertyUuid}`

    updateSavedProperty(requestParams, property.propertyUuid).then(({ error }) => {
      if (error) {
        setToastVariation('requestError')
        setRequestError(error)
      } else if (addingFavorite) {
        maybeShowPostFavoriteSearchModal(property)
      }
    })

    setRequestError(false)
  }

  const confirmSaveSearch = () => {
    trackEvent(ACTION_STATE, 'saved_search_logged_in')
    setSaveSearchConfirmIsVisible(true)
  }

  const attemptSaveSearch = useCallback(
    (frequency?: string) => {
      trackEvent(ACTION_TAP, 'attempt_save_search')
      setToastVariation('savedSearchCreated')
      setRequestError(false)

      if (!currentUser?.id) {
        setSavedSearchSignIn(true)
        setSignInAddress(`Your search criteria for ${locationTitle}`)
        if (hasSubmittedEmail) {
          setSignInSignUpVariation('confirm')
        } else {
          setSignInSignUpVariation('signIn')
        }
        trackEvent(ACTION_STATE, 'saved_search_logged_out')
        setSignInSource('Saved_Search')
        setSignInSignUpIsVisible(true)
      } else if (savedSearches && savedSearches.length >= 20) {
        setToastVariation('savedSearchLimit')
        setShowToast(true)
      } else if (!frequency) {
        confirmSaveSearch()
      } else {
        createSavedSearch(frequency)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentUser, hasSubmittedEmail, locationTitle, savedSearches, filters]
  )

  const addUtmParamsToSavedSearchBody = (
    savedSearchBody: ISavedSearchBodyRequest
  ): ISavedSearchBodyRequest => {
    const { utmSource, utmMedium, utmCampaign, utmContent, utmTerm } = getUtmParamsFromUrl()

    return {
      ...savedSearchBody,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_term: utmTerm
    }
  }

  const buildNewSavedSearchBody = (frequency: string): ISavedSearchBodyRequest => {
    const searchRequestFilters = buildRequestFilters(filters)
    const searchUrl = `?save_search=true${searchRequestFilters}`
    let newSavedSearchBody: ISavedSearchBodyRequest = {
      search_url: searchUrl,
      frequency: frequency,
      source: 'home_search'
    }

    newSavedSearchBody = addUtmParamsToSavedSearchBody(newSavedSearchBody)

    return newSavedSearchBody
  }

  const createSavedSearch = (frequency: string) => {
    const newSavedSearchBody: ISavedSearchBodyRequest = buildNewSavedSearchBody(frequency)

    requestData({
      url: SAVED_SEARCHES_URL,
      method: 'post',
      body: newSavedSearchBody,
      ignoreJsonFormat: true,
      includeBearerToken: false
    }).then(({ error }) => {
      if (error) {
        setToastVariation('requestError')
        setRequestError(error)
      } else {
        setSavedSearchCreated(true)
        trackEvent(ACTION_STATE, 'save_search')
        setSaveSearchConfirmIsVisible(false)
      }
    })
  }

  const signUpAndSaveSearch = (email, name, redirect, setModalLoading) => {
    const savedSearchBody: ISavedSearchBodyRequest = buildNewSavedSearchBody('daily')
    savedSearchBody['email'] = email
    savedSearchBody['client_name'] = name
    savedSearchBody['redirect_url'] = redirect
    savedSearchBody['ref'] = 'house_hunter'

    requestData({
      url: HAPI_SIGN_UP_SAVE_SEARCH_URL,
      method: 'post',
      body: savedSearchBody,
      ignoreJsonFormat: true,
      includeBearerToken: true
    }).then(({ data }) => {
      if (data) {
        fetchUserData()
        trackEvent(ACTION_VIEW, 'LoginModal_SignUpSaveSearch')
        goToConfirm()
        setModalLoading(false)
      }
    })
  }

  const incrementSentLoginLink = () => {
    const timesSent = sentLoginLinkTimes + 1

    if (timesSent > 1) {
      setToastVariation('resentLoginLink')
      setResentLoginLink(true)
    }

    setSentLoginLinkTimes(timesSent)
    setResentLoginLink(false)
  }

  const goToLeadFormVariation = (variation: string) => {
    setLeadFormVariation(variation)
    setLeadFormModalIsVisible(true)
  }

  const goToConfirm = () => {
    setSignInSignUpVariation('confirm')
    setHasSubmittedEmail(true)
  }

  useEffect(() => {
    setSavedSearchCreated(false)
    const searchThisArea = !!filters.find(
      filter =>
        filter.field ===
        ('top_left_lat' || 'top_left_lon' || 'bottom_right_lat' || 'bottom_right_lon')
    )
    setDisableSavedSearch(searchThisArea || savedSearchMatchesFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, savedSearchMatchesFilters])

  useEffect(() => {
    if (propertiesData) {
      if (desktop) {
        setShowMap(true)
        setInitialShowMap(true)
      }

      if (sessionId) {
        trackEvent(ACTION_VIEW, 'total_listing_results', totalPropertiesCount, true)

        if (propertiesData.some(property => property.featured)) {
          let paywall = propertiesData.some(property => property.locked) ? 'paywall' : 'no_paywall'
          let logged_in = Boolean(currentUser) ? 'logged_in' : 'logged_out'
          let subscribed = Boolean(currentUser?.goldRushSubscribed)
            ? 'subscribed'
            : 'not_subscribed'
          let event = `results_viewed:ultra:${paywall}:${logged_in}:${subscribed}`

          trackEvent(ACTION_VIEW, event, propertiesData.length, true)

          sendToGtmDataLayer({
            dl_hotjar_event: 'ultra-ultra_paywall'
          })
        } else {
          trackEvent(ACTION_VIEW, 'results_viewed', propertiesData.length, true)
        }
      }

      if (Boolean(newBuyer)) {
        sendToGtmDataLayer({
          dl_hotjar_event: 'home_run_experiment'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, propertiesData, currentUser, sessionId])

  useEffect(() => {
    const shouldShowToast = requestError || savedSearchCreated || resentLoginLink
    setShowToast(shouldShowToast)
  }, [requestError, savedSearchCreated, resentLoginLink])

  useEffect(() => {
    const searchCreatedOnRedirect = (searchParams as any)['save_search'] === 'true'
    if (searchCreatedOnRedirect) {
      setSavedSearchCreated(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledBody data-testid="properties-search">
      <Helmet>
        <title>{`${locationTitle ? `${locationTitle} ` : ''}Real Estate & ${titleText}`}</title>
        <link
          rel="canonical"
          href={buildSearchUrl(currentSlug, pageType, areaType, bedroomCount)}
        />
        <meta name="description" content={locationDescription} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: `${titleText}`,
            image: [
              propertiesContext?.listingImages[0]?.urls[0] ||
                'https://mls-media.homelight.com/trestle/property/image/property_image_TrestleMLS_1065096562/property_image_TrestleMLS_1065096562_0.jpg'
            ],
            offers: {
              '@type': 'AggregateOffer',
              highPrice: propertiesContext?.meta?.max_price || 0,
              lowPrice: propertiesContext?.meta?.min_price || 0,
              priceCurrency: 'USD'
            }
          })}
        </script>
      </Helmet>
      <BodyLeft
        id="properties-body"
        className={showMap ? '' : 'full-width'}
        data-testid="properties-body-left"
        ref={bodyRef}
      >
        {showSearchBar && (
          <SearchBar
            showMap={showMap}
            isModalVisible={isModalVisible}
            mobileFilterPopoverOpen={mobileFilterPopoverOpen}
          />
        )}
        <SearchPageTitle>{resultsTitle}</SearchPageTitle>
        <StyledFilterList
          showMap={showMap}
          isModalVisible={isModalVisible}
          mobileFilterPopoverOpen={mobileFilterPopoverOpen}
          data-hide-search-bar={!showSearchBar}
        >
          <Filters
            showMap={showMap}
            appliedFilters={appliedFilters}
            attemptSaveSearch={attemptSaveSearch}
            savedSearchCreated={savedSearchCreated}
            disableSavedSearch={disableSavedSearch}
            savedSearchMatchesFilters={savedSearchMatchesFilters}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            mobileFilterPopoverOpen={mobileFilterPopoverOpen}
            setMobileFilterPopoverOpen={setMobileFilterPopoverOpen}
            resetCurrentPage={resetCurrentPage}
            sortOptions={SORT_OPTIONS}
            selectedSortLabel={sorts.length ? sorts[0].label : DEFAULT_PROPERTY_SORT.label}
            setSort={(sortOption: ISort) => {
              setSorts([sortOption])
              resetCurrentPage()
            }}
            showGoldRush={showGoldRush}
          />
          <ShowMapButton setShowMap={handleSetShowMap} showMap={showMap} />
        </StyledFilterList>
        <SortFlex showMap={showMap} data-hide-search-bar={!showSearchBar}>
          {!propertiesContext.properties.propertiesLoading && (
            <PaginationSummary paginationData={propertiesContext} fontSize={'14px'} />
          )}
        </SortFlex>
        {/* @ts-ignore */}
        <ListView
          favoriteProperty={favoriteProperty}
          listRef={listScrollListener}
          activeTest={activeTest}
          setUltraRateModal={setUltraRateModal}
          hasFeatured={hasFeatured}
        />
        <BreadCrumbs />
        {searchAreaType === 'state' && <TopAgentsInState />}
        <Faq
          getFaqData={getFaqData}
          faqData={faqData}
          currentSlug={currentSlug}
          locationTitle={locationTitle}
          pageAreaType={pageAreaType}
        />
      </BodyLeft>
      <Suspense
        fallback={
          <MapLoadingWrapper>
            <LoadingSpinner color={darkBlue} />
          </MapLoadingWrapper>
        }
      >
        {initialShowMap && (
          <ErrorBoundary>
            <Map
              favoriteProperty={favoriteProperty}
              showMap={showMap}
              closeMap={() => handleSetShowMap(false)}
              currentShape={currentShape}
              onboardingExperience={showOnboarding}
            />
          </ErrorBoundary>
        )}
      </Suspense>
      {leadFormModalIsVisible && (
        <ErrorBoundary>
          <StyledModalBackground>
            {/* @ts-ignore */}
            <StyledLeadFormContainer ref={leadFormModalRef} onBlur={e => handleModalBlur(e)}>
              <Suspense fallback={<FallbackLoadingSpinner />}>
                <LeadFormModal
                  variation={leadFormVariation}
                  trackEvent={trackEvent}
                  closeModal={() => setLeadFormModalIsVisible(false)}
                  locationTitle={locationTitle}
                  goToLeadFormVariation={goToLeadFormVariation}
                  setShowToast={setShowToast}
                  setToast={setToastVariation}
                  setError={setRequestError}
                  slug={currentSlug}
                  faqData={faqData}
                  incrementSentLoginLink={incrementSentLoginLink}
                  currentUser={currentUser}
                  city={city}
                  state={state}
                  fetchUserData={fetchUserData}
                />
              </Suspense>
            </StyledLeadFormContainer>
          </StyledModalBackground>
        </ErrorBoundary>
      )}
      {signInSignUpIsVisible && (
        <ErrorBoundary>
          <StyledModalBackground>
            {/* @ts-ignore */}
            <StyledModalContainer ref={signUpModalRef} onBlur={e => handleModalBlur(e)}>
              <Suspense fallback={<FallbackLoadingSpinner />}>
                <SignInSignUp
                  variation={signInSignUpVariation}
                  emailVal={signInEmail}
                  address={signInAddress}
                  searchFilters={filters}
                  searchSorts={sorts}
                  currentPage={currentPage}
                  saveSearch={savedSearchSignIn}
                  sentLoginLinkTimes={sentLoginLinkTimes}
                  incrementSentLoginLink={incrementSentLoginLink}
                  setEmailVal={setSignInEmail}
                  goToSignUp={() => setSignInSignUpVariation('signUp')}
                  goToSignIn={() => setSignInSignUpVariation('signIn')}
                  goToConfirm={goToConfirm}
                  closeModal={() => setSignInSignUpIsVisible(false)}
                  signInSource={signInSource}
                  signUpAndSaveSearch={signUpAndSaveSearch}
                />
              </Suspense>
            </StyledModalContainer>
          </StyledModalBackground>
        </ErrorBoundary>
      )}
      {saveSearchConfirmIsVisible && (
        <ErrorBoundary>
          <ModalBackground>
            {/* @ts-ignore */}
            <StyledModalContainer ref={saveSearchConfirmRef} onBlur={e => handleModalBlur(e)}>
              <SaveSearchConfirm
                locationTitle={locationTitle}
                closeModal={() => setSaveSearchConfirmIsVisible(false)}
                createSavedSearch={createSavedSearch}
              />
            </StyledModalContainer>
          </ModalBackground>
        </ErrorBoundary>
      )}
      {postFavoriteSearchIsVisible && (
        <ErrorBoundary>
          <ModalBackground>
            {/* @ts-ignore */}
            <StyledModalContainer ref={postFavoriteSearchRef} onBlur={e => handleModalBlur(e)}>
              <PostFavoriteSearchModal
                property={lastFavoritedProperty}
                slug={currentSlug}
                locationTitle={locationTitle}
                filters={filters}
                currentUser={currentUser}
                visitorId={visitorId}
                closeModal={() => setPostFavoriteSearchIsVisible(false)}
                setSavedSearchCreated={setSavedSearchCreated}
                setShowToast={setShowToast}
                setToast={setToastVariation}
                setError={setRequestError}
              />
            </StyledModalContainer>
          </ModalBackground>
        </ErrorBoundary>
      )}
      {savedSearchPromptIsVisible && (
        <ErrorBoundary>
          <ModalBackground>
            {/* @ts-ignore */}
            <StyledModalContainer ref={savedSearchPromptRef} onBlur={e => handleModalBlur(e)}>
              <SavedSearchPromptModal
                locationTitle={locationTitle}
                filters={filters}
                appliedFilters={appliedFilters}
                closeModal={() => setSavedSearchPromptIsVisible(false)}
                attemptSaveSearch={attemptSaveSearch}
              />
            </StyledModalContainer>
          </ModalBackground>
        </ErrorBoundary>
      )}
      {callMeMaybeModal && (
        <CallMeMaybeModal
          callMeMaybeModal={callMeMaybeModal}
          setCallMeMaybeModal={setCallMeMaybeModal}
          callMeMaybeState={callMeMaybeState}
          setCallMeMaybeState={setCallMeMaybeState}
          cmmLeadId={cmmLeadId}
        />
      )}
      {newBuyerLead && (
        <ErrorBoundary>
          <SavedHomesProvider savedHomesType="requestedTour">
            <NewBuyerLeadModal
              onClose={() => closeParamModal('new_buyer_client')}
              currentUser={currentUser}
              currentSlug={currentSlug}
              triggerAgentConfirmation={triggerAgentConfirmation}
            />
          </SavedHomesProvider>
        </ErrorBoundary>
      )}
      {confirmAgent && (
        <ErrorBoundary>
          <AgentConfirmation
            onClose={() => closeParamModal('confirm_agent')}
            currentUser={currentUser}
            pendingRequestUuid={pendingRequestUuid}
          />
        </ErrorBoundary>
      )}
      {ultraRateModal && <UltraRateModal setUltraRateModal={setUltraRateModal} />}
      <PropertySearchToast show={showToast} variation={toastVariation} />
      {showGoldRush && hasFeatured && routerSearchParams.get('ulr-toast') !== 'false' ? (
        <UltraRateToast
          show={true}
          totalFeatured={totalFeaturedCount}
          onClose={() =>
            setSearchParams(
              prev => {
                prev.set('ulr-toast', 'false')
                return prev
              },
              { replace: true }
            )
          }
        />
      ) : null}
    </StyledBody>
  )
}

export const PropertySearchBody = memo(PropertySearchBodyComponent)
