import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { ILocationContext, LocationContext } from '../../contexts/LocationContext'
import { Link } from '../Footer/FooterLink'
import { TOP_CITIES_BY_STATE } from '../../constants/topCitiesByState'

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  margin-bottom: 32px;
`

const ListItem = styled.li`
  padding: 0;
  margin: 0;
`

export const TopAgentsInState = () => {
  const { currentSlug, stateFromIP } = useContext(LocationContext) as ILocationContext
  const [citiesInState, setCitiesInState] = useState<{ name: string; url: string }[]>([])

  useEffect(() => {
    if (currentSlug?.toLowerCase()) {
      const stateCode = currentSlug.toLowerCase()
      const cities = TOP_CITIES_BY_STATE[stateCode as keyof typeof TOP_CITIES_BY_STATE]
      setCitiesInState(cities || [])
    }
  }, [currentSlug])

  return (
    <div>
      <h2>Find top real estate agents in {stateFromIP}</h2>
      <UnstyledList>
        {citiesInState
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((city, index) => (
            <ListItem key={index}>
              <Link href={city.url}>{city.name} top real estate agents</Link>
            </ListItem>
          ))}
      </UnstyledList>
    </div>
  )
}
